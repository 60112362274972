<template>
  <div style="position: absolute; bottom: 0; top: 60px; right: 0; left: 0">
    <div style="position: absolute; bottom: 0; top: 0; left: 0; overflow: auto" :style="`width: ${showList ? 300 : 50}px`" class="chat-list-block blue-grey lighten-5">
      <a-loader v-if="loading" />
      <div v-if="false" style="margin-right: 10px">
        <div style="width: 100%">
          <input style="padding-right: 45px" class="whatsapp-input" v-model="filtersNumber" placeholder="Поиск по номеру" />
        </div>
        <div class="pa-2 ml-1" style="position: absolute; right: 0; top: 0">
          <v-btn icon @click="search(filtersNumber)" title="Поиск"><v-icon>fas fa-search </v-icon></v-btn>
        </div>
      </div>
      <div style="margin-right: 10px">
        <div class="pa-2 ml-1" style="position: absolute; right: 0; top: 0">
          <v-btn icon @click="showList = !showList" title="Поиск"
            ><v-icon>fas {{ showList ? "fa-arrow-left" : "fa-arrow-right" }} </v-icon></v-btn
          >
        </div>
      </div>
      <div
        v-if="showList"
        style="position: absolute; bottom: 0px; top: 51px; left: 0px; overflow: auto"
        :style="`width: ${showList ? 300 : 50}px`"
        ref="chat_clients_block"
      >
        <div>
          <v-list two-line>
            <v-list-item-group v-model="selected" @change="changeRoom(selected)">
              <template v-for="(item, index) in data">
                <chat-item :item="item" />
                <v-divider v-if="index < data.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </div>

    <div style="position: absolute; bottom: 0; top: 0; right: 0" :style="`left: ${showList ? 300 : 50}px`">
      <chat-block style="position: absolute; bottom: 0; top: 0; right: 0; left: 0" :id="number" :chat="data[selected]" @close="changeRoom()" :isAll="isAll" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    chatItem: () => import("./chatItem.vue"),
    chatBlock: () => import("./chatBlock.vue"),
  },
  props: {
    data: Array,
    loading: Boolean,
    isAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showClientDialog: false,
      number: null,
      selected: null,
      filtersNumber: "",
      showList: true,
    };
  },
  computed: {
    chat_name() {
      let res = this.item?.data?.offer?.title || this.item.id;
      return res;
    },
  },
  watch: {
    showClientDialog() {
      if (!this.showClientDialog) {
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, true);
    this.$root.$on("websocket", this.registerWS);
    //this.$root.$on("set_viewed_wa", this.registerSetViewed);
  },
  destoyed() {
    this.$root.$off("websocket", this.registerWS);
    this.$root.$off("set_viewed_wa", this.registerSetViewed);
    document.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    changeRoom(v) {
      console.log("change room", v);
      this.number = this.data[v]?.id;
      this.selected = v;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showList = !this.number;
      }
    },
    onScroll(e) {
      if (this.$refs.chat_clients_block) {
        let el = this.$refs.chat_clients_block;
        let offset = 200;
        if (e.target == el) {
          if (el.scrollHeight - el.offsetHeight <= el.scrollTop + offset) {
            this.$emit("onScrollEnd");
          }
        }
      }
    },
    search(number) {
      let n = number.replace(/\D/g, "").substring(1);
      this.$emit("search", n);
    },
    registerWS(data) {
      if (data && data.e == "whatsapp") {
        const r = data.r;
        if (r.direction == 1 && r.viewed == 0) {
          for (const el of this.data) {
            if (el.wa_number == r.sender_number) {
              el.wa_unviewed++;
            }
          }
        }
      }
    },
    registerSetViewed(data) {
      for (const el of this.data) {
        if (el.wa_number == data.sender_number) {
          el.wa_unviewed = 0;
        }
      }
    },
    registerIncome(data) {
      for (const el of this.data) {
        if (el.wa_number == data.sender_number) {
          el.wa_unviewed++;
        }
      }
    },
    showDateTime(item) {
      let d = new Date(item);
      return d.toLocaleString("ru");
    },
  },
};
</script>
